<template>
  <el-dialog title="引入源单" :visible.sync="openBills" :before-close="handleClose" width="1200px" v-dialogDrag>
    <el-form ref="form" :model="queryParams" label-width="70px">
      <div class="x-f">
        <el-radio-group v-model="queryParams.sourceBillType" size="mini" v-if="orderTypes === '140306'">
          <el-radio-button :label="140301">门店订货</el-radio-button>
          <el-radio-button :label="140302">门店原料订货</el-radio-button>
          <!-- <el-radio-button :label="170207">其他订货</el-radio-button>
        <el-radio-button :label="170208">其他原料订货</el-radio-button> -->
        </el-radio-group>
        <el-form-item label="" prop="billNo">
          <el-input size="mini" v-model="queryParams.billNo" placeholder="单据编号"></el-input>
        </el-form-item>

        <el-form-item v-if="!['140306'].includes(orderTypes)" label="引入状态" prop="shop_import_status ">
          <el-select size="mini" v-model="queryParams.importStatus" placeholder="请选择引入状态" filterable clearable>
            <el-option v-for="dict in dict.type.shop_import_status" :key="dict.value" :label="dict.label"
              :value="dict.value" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="!['140306'].includes(orderTypes)" label="单据日期" prop="storeId">
          <el-date-picker class="marR10" size="mini" v-model="datetime" type="daterange" align="right" unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="调出仓库" prop="outStoreId" v-if="isOutStore">
          <el-select size="mini" v-model="queryParams.outStoreId" placeholder="调出仓库" filterable clearable>
            <el-option v-for="item in storeData" :key="item.storeId" :label="item.storeName" :value="item.storeId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订货仓库" prop="orderStoreId" v-if="isOrderStore && !['140306'].includes(orderTypes)">
          <el-select size="mini" v-model="queryParams.orderStoreId" placeholder="订货仓库" filterable clearable>
            <el-option v-for="item in storeData" :key="item.storeId" :label="item.storeName" :value="item.storeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button class="marL10" size="mini" type="primary" @click="getTable">查询</el-button>
      </div>
    </el-form>
    <el-table class="marT10" ref="multipleTable" @select-all="selectAll" @select="select" v-loading="loading"
      tooltip-effect="dark" style="width: 100%" :data="tableData" @row-click="handleRowClick"
      @selection-change="handleSelectionChange" border max-height="500" height="500"
      :default-sort="{ prop: 'date', order: 'descending' }">
      <!-- <el-table-column label="序号" type="index" width="80" align="center" /> -->
      <el-table-column type="selection" width="50" align="center" />
      <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
      <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" width="155">
      </el-table-column>
      <el-table-column sortable label="单据日期" align="center" prop="billDate" width="155" />
      <el-table-column label="调出仓库" align="center" prop="outStoreName" width="120"
        v-if="!['140306'].includes(orderTypes)" />
      <el-table-column label="调入仓库" align="center" prop="inStoreName" width="120"
        v-if="!['140306'].includes(orderTypes)">
        <template slot-scope="scope">
          <span>{{
            shopInStoreName == 'inStoreName'
              ? scope.row.inStoreName
              : scope.row.orderStoreName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已引入单据号" align="center" prop="finishBillNo" width="120"
        v-if="!['140306'].includes(orderTypes)" />
      <el-table-column label="已引入单据类型" align="center" prop="finishBillTypeName" width="120"
        v-if="!['140306'].includes(orderTypes)" />
      <el-table-column label="引入状态" align="center" prop="importStatusName" width="120"
        v-if="!['140306'].includes(orderTypes)" />
      <el-table-column show-overflow-tooltip label="单据金额" align="center" prop="billMoney"
        v-if="!['140306'].includes(orderTypes)" width="120" />
      <el-table-column show-overflow-tooltip label="单据数量" align="center" prop="billQty"
        v-if="!['140306'].includes(orderTypes)" width="120" />
      <el-table-column show-overflow-tooltip label="备注" align="center" prop="billRemark"
        v-if="!['140306'].includes(orderTypes)" width="155" />
      <!-- <el-table-column
        sortable
        :label="orderTime"
        align="center"
        prop="shopBillDate"
        width="155"
        v-if="!['140306'].includes(orderTypes)"
      /> -->
      <!-- <el-table-column
        :label="orderNo"
        align="center"
        prop="shopBillNo"
        width="155"
        v-if="!['140306'].includes(orderTypes)"
      /> -->
      <!-- <el-table-column label="订货仓库" align="center" prop="inStoreName" width="155" v-if="['140306'].includes(orderTypes)" /> -->
      <el-table-column label="商品编码" align="center" prop="goodsNo" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="商品名称" align="center" prop="goodsName" width="155"
        v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="条码" align="center" prop="barcode" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="规格" align="center" prop="goodsSpec" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="单位" align="center" prop="unitName" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="单价" align="center" prop="unitPrice" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="金额" align="center" prop="unitMoney" width="155" v-if="['140306'].includes(orderTypes)" />
      <el-table-column label="备注" align="center" prop="remark" width="155" v-if="['140306'].includes(orderTypes)" />
    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
      @pagination="getList" />
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { allStoreList } from '@/api/system/store' //仓库接口
import { getSourceBill } from '@/api/purchase/index'
import { uniqWith } from 'lodash' //去重
import {
  listOrder,
  decorationFindReferenceData,
  freshlyBakedFindReferenceData,
  getOrderDetailData,
  getFindDetailGoodsInfo,
  getfreshlyBakedDetailGoodsInfo,
} from '@/api/shop/bill/shopBills'
export default {
  name: 'shopBills',
  dicts: ['shop_import_status'],
  data() {
    return {
      selectData: [],
      billId: undefined, //单据ID
      shopInStoreName: 'inStoreName', //调入仓库
      orderTime: '', //单据日期
      orderNo: '', //单据编号
      importStatus: undefined, //选中状态
      ids: [], //选中Id
      isOutStore: false, //调出
      isOrderStore: false, //订货
      total: 0, //单据条数
      loading: false, //加载框
      isOpenBills: false, //关闭
      datetime: undefined, //单据查询时间
      queryParams: { pageNum: 1, pageSize: 15, sourceBillType: 140301 }, //查询条件
      storeData: [], //仓库数据
      tableData: [], //对应表格
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  props: {
    moveAffirmObj: {
      type: Object,
      default: () => { }
    },
    //单据开关
    openBills: {
      type: Boolean,
      default: false
    },
    ishead: {
      type: Boolean,
      default: false
    },
    detailList: {
      type: Array,
      default: []
    },
    //单据类型
    orderTypes: {
      type: String,
      default: ''
    },
    shopType: {
      type: [Number, String],
      default: 1
    },
  },
  watch: {
    moveAffirmObj: {
      async handler(newVal) {
        if (newVal) {
          this.queryParams.inStoreId = newVal.inStoreId
          this.queryParams.billTypes = newVal.billTypes
          await this.getList()
          this.tableData = this.tableData.filter(
            item => item.billId == newVal.billId
          )
          if (this.tableData.length > 0) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(this.tableData[0])
            })
          }
        }
      },
      deep: true,
      immediate: true
    },
    //订单时间
    datetime(newVal) {
      if (newVal) {
        console.log(newVal)
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
      }
      if (!newVal) {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
      }
    },
    //单据开关
    openBills(newVal) {
      if (!newVal) {
        this.reset()
      } else {
        if (!this.moveAffirmObj.billId) {
          this.getList()
        }
      }
    },
    //单据类型
    orderTypes: {
      handler() {
        if (this.orderTypes === '140305') {
          //调出仓库
          this.isOutStore = true
          //表格单据日期单据号
          this.orderTime = '门店调入单据日期'
          this.orderNo = '门店调入单据号'
          this.shopInStoreName = 'inStoreName' //调入仓库
        } else if (this.orderTypes === '170306') {
          //调出仓库
          this.isOutStore = true
          //表格单据日期单据号
          this.orderTime = '门店调入单据日期'
          this.orderNo = '门店调入单据号'
          this.shopInStoreName = 'inStoreName' //调入仓库
        } else if (this.orderTypes === '170406') {
          //调出仓库
          this.isOutStore = true
          //表格单据日期单据号
          this.orderTime = '门店调入单据日期'
          this.orderNo = '门店调入单据号'
          this.shopInStoreName = 'inStoreName' //调入仓库
        } else if (this.orderTypes === '140306') {
          //订单仓库
          this.isOrderStore = true
          //表格单据日期单据号
          this.orderTime = '门店调出单据日期'
          this.orderNo = '门店调出单据号'
          this.shopInStoreName = 'orderStoreName' //调入仓库
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    //单选表格
    handleSelectionChange(selection) {
      this.importStatus = selection.map(item => item.importStatus)
      if (this.orderTypes == '140306') {
        // this.selectData
        console.log(this.selectData, 'this.selectData去重之后');
        this.selectData = uniqWith(selection, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
      } else {
        console.log(selection, 'selection')
        let arr = selection.filter(item => item.importStatus == 1)
        console.log(arr, 'arr')
        if (arr.length == 0) {
          this.$refs.multipleTable.clearSelection()
          return
        }
        this.ids = selection.map(item => item.billId) //单据id
      }
    },
    //单选
    select(selection, row) {
      if (['140306'].includes(this.orderTypes)) {
        let isCheck = selection.length && selection.indexOf(row) !== -1
        if (isCheck) {
          this.selectData = [...this.selectData, row]
        } else {
          console.log(this.selectData, 'this.selectData单选');
          this.selectData = this.selectData.filter(item => item.sourceBillDetailId != row.sourceBillDetailId)
        }
      } else {
        if (selection.length > 1) {
          let del_row = selection.shift()
          this.$refs.multipleTable.toggleRowSelection(del_row, false)
        }
      }
    },
    selectAll(selection) {
      if (['140306'].includes(this.orderTypes)) {
        console.log(selection, 'selection 全选')
        this.selectData = [...this.selectData, ...selection]
        console.log(this.selectData, 'this.selectData  全选')
        return
      } else {
        if (selection.length > 1) {
          selection.length = 1
        }
      }
    },
    // 重置表格
    reset() {
      this.shopInStoreName = 'inStoreName' //调入仓库
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        sourceBillType: 140301
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (this.orderTypes == '140305' || this.orderTypes == '170306' || this.orderTypes == '170406') {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(row)
        return
      } else {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    getSourceBillFun() {
      let query = this.queryParams
      query.billType = '140306'
      let urlType = this.shopType == 1 ? '/api/system/source/bill/getSourceBillOrder' : '/api/system/franchise/source/bill/getSourceBillOrder'
      getSourceBill(urlType, query).then(
        response => {
          this.tableData = response.rows.map(x => ({
            ...x,
            sourceBillType: x.billType
          }))
          if (this.orderTypes === '140306') {
            let list = this.detailList.filter(item => item.sourceBillDetailId != undefined)
            this.selectData = [...this.selectData, ...list]
            if (this.selectData.length > 0) {
              this.$refs.multipleTable.clearSelection()
              this.selectData.map((item, index) => {
                this.tableData.map((v, i) => {
                  if (item.sourceBillDetailId == v.sourceBillDetailId) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(this.tableData[i])
                    })
                  }
                })
              });
            }
          }
        }
      )
    },
    //初始化数据
    async getList() {
      this.loading = true
      //获取列表
      if (this.orderTypes === '140305') {
        // this.queryParams.importStatus = 1
        if (!this.queryParams.inStoreId) return
        const res1 = await listOrder(this.queryParams)
        this.tableData = res1.rows
      } else if (this.orderTypes === '140306') {
        // const res1 = await shopListOrder(this.queryParams)
        // this.tableData = res1.rows
        // console.log('tableData', this.tableData)
        if (!this.queryParams.inStoreId) return
        this.getSourceBillFun()
      } else if (this.orderTypes === '170306') {
        if (!this.queryParams.inStoreId) return
        const res1 = await decorationFindReferenceData(this.queryParams)
        this.tableData = res1.rows
        // const res1 = await shopListOrder(this.queryParams)
        // this.tableData = res1.rows
        // console.log('tableData', this.tableData)
      } else if (this.orderTypes === '170406') {
        if (!this.queryParams.inStoreId) return
        const res1 = await freshlyBakedFindReferenceData(this.queryParams)
        this.tableData = res1.rows
        // const res1 = await shopListOrder(this.queryParams)
        // this.tableData = res1.rows
        // console.log('tableData', this.tableData)
      }
      //获取仓库
      const res = await allStoreList()
      this.storeData = res.data
      this.loading = false
    },
    //查询
    async getTable() {
      this.loading = true
      //获取列表
      if (this.orderTypes === '140305') {
        // this.queryParams.importStatus = 1
        const res1 = await listOrder(this.queryParams)
        this.tableData = res1.rows
      } else if (this.orderTypes === '140306') {
        // const res1 = await shopListOrder(this.queryParams)
        // this.tableData = res1.rows
        this.getSourceBillFun()
      } else if (this.orderTypes === '170306') {
        const res1 = await decorationFindReferenceData(this.queryParams)
        this.tableData = res1.rows
      } else if (this.orderTypes === '170406') {
        const res1 = await freshlyBakedFindReferenceData(this.queryParams)
        this.tableData = res1.rows
      }

      this.loading = false
    },

    //点击确定
    async onSubmit() {
      console.log('this.ids', this.ids, 'this.selectData', this.selectData);
      if (this.ids.length > 0 || this.selectData.length > 0) {
        // if (this.orderTypes != '140306') {
        //   if (this.importStatus[0] != '未引入') {
        //     this.$message({
        //       message: '只能引入状态为“未引入”的订单',
        //       type: 'warning'
        //     })
        //     return
        //   }
        // }
        if (this.ishead) {
          this.$confirm(
            '引入前会清空之前新增商品，避免前后台单据出现不一致性错误！, 是否继续?',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(async () => {
              this.submitFun()
            })
            .catch(() => { })
        } else {
          this.submitFun()
        }
      } else {
        this.$emit('updateOpenBills', false)
      }
    },
    async submitFun() {
      if (this.orderTypes === '140305') {
        const res = await getOrderDetailData({ billIds: this.ids, pageNum: 1, pageSize: 9999 })
        this.$emit('shopBillsDate', res.rows)
      } else if (this.orderTypes === '140306') {
        // const res = await shopGoodsOrder(this.ids)
        // this.getSourceBillFun()
        let list = uniqWith(this.selectData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
        this.$emit('shopBillsDate', list)
      } else if (this.orderTypes === '170306') {
        // const res = await shopGoodsOrder(this.ids)
        // this.getSourceBillFun()
        console.log(this.ids, 'this.selectData');
        const res = await getFindDetailGoodsInfo({ billIds: this.ids, pageNum: 1, pageSize: 9999 })
        this.$emit('shopBillsDate', res.rows)
        // let list = uniqWith(this.selectData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
        // this.$emit('shopBillsDate', list)
      } else if (this.orderTypes === '170406') {
        // const res = await shopGoodsOrder(this.ids)
        // this.getSourceBillFun()
        console.log(this.ids, 'this.selectData');
        const res = await getfreshlyBakedDetailGoodsInfo({ billIds: this.ids, pageNum: 1, pageSize: 9999 })
        this.$emit('shopBillsDate', res.rows)
        // let list = uniqWith(this.selectData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
        // this.$emit('shopBillsDate', list)
      }
      this.$emit('IntroductionStatus', true) //引入状态
      this.$emit('updateOpenBills', false) //关闭弹框
    },
    //点击取消
    cancel() {
      this.$emit('updateOpenBills', false)
    },
    //关闭前回调
    handleClose(done) {
      this.selectData = []
      this.$emit('updateOpenBills', false)
    }
  }
}
</script>

<style lang="scss" scoped></style>
