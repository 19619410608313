var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "引入源单",
        visible: _vm.openBills,
        "before-close": _vm.handleClose,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.openBills = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.queryParams, "label-width": "70px" },
        },
        [
          _c(
            "div",
            { staticClass: "x-f" },
            [
              _vm.orderTypes === "140306"
                ? _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.queryParams.sourceBillType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "sourceBillType", $$v)
                        },
                        expression: "queryParams.sourceBillType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 140301 } }, [
                        _vm._v("门店订货"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 140302 } }, [
                        _vm._v("门店原料订货"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "billNo" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "单据编号" },
                    model: {
                      value: _vm.queryParams.billNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "billNo", $$v)
                      },
                      expression: "queryParams.billNo",
                    },
                  }),
                ],
                1
              ),
              !["140306"].includes(_vm.orderTypes)
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "引入状态", prop: "shop_import_status " },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            placeholder: "请选择引入状态",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryParams.importStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "importStatus", $$v)
                            },
                            expression: "queryParams.importStatus",
                          },
                        },
                        _vm._l(
                          _vm.dict.type.shop_import_status,
                          function (dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !["140306"].includes(_vm.orderTypes)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "单据日期", prop: "storeId" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "marR10",
                        attrs: {
                          size: "mini",
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions,
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.datetime,
                          callback: function ($$v) {
                            _vm.datetime = $$v
                          },
                          expression: "datetime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOutStore
                ? _c(
                    "el-form-item",
                    { attrs: { label: "调出仓库", prop: "outStoreId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            placeholder: "调出仓库",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryParams.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "outStoreId", $$v)
                            },
                            expression: "queryParams.outStoreId",
                          },
                        },
                        _vm._l(_vm.storeData, function (item) {
                          return _c("el-option", {
                            key: item.storeId,
                            attrs: {
                              label: item.storeName,
                              value: item.storeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOrderStore && !["140306"].includes(_vm.orderTypes)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "订货仓库", prop: "orderStoreId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            placeholder: "订货仓库",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryParams.orderStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "orderStoreId", $$v)
                            },
                            expression: "queryParams.orderStoreId",
                          },
                        },
                        _vm._l(_vm.storeData, function (item) {
                          return _c("el-option", {
                            key: item.storeId,
                            attrs: {
                              label: item.storeName,
                              value: item.storeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "marL10",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.getTable },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticClass: "marT10",
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.tableData,
            border: "",
            "max-height": "500",
            height: "500",
            "default-sort": { prop: "date", order: "descending" },
          },
          on: {
            "select-all": _vm.selectAll,
            select: _vm.select,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "序号",
              align: "center",
              prop: "userId",
              width: "80",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              label: "单据编号",
              align: "center",
              prop: "billNo",
              width: "155",
            },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "单据日期",
              align: "center",
              prop: "billDate",
              width: "155",
            },
          }),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "调出仓库",
                  align: "center",
                  prop: "outStoreName",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "调入仓库",
                  align: "center",
                  prop: "inStoreName",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.shopInStoreName == "inStoreName"
                                  ? scope.row.inStoreName
                                  : scope.row.orderStoreName
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3084170158
                ),
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "已引入单据号",
                  align: "center",
                  prop: "finishBillNo",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "已引入单据类型",
                  align: "center",
                  prop: "finishBillTypeName",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "引入状态",
                  align: "center",
                  prop: "importStatusName",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "单据金额",
                  align: "center",
                  prop: "billMoney",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "单据数量",
                  align: "center",
                  prop: "billQty",
                  width: "120",
                },
              })
            : _vm._e(),
          !["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "备注",
                  align: "center",
                  prop: "billRemark",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  align: "center",
                  prop: "goodsNo",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  align: "center",
                  prop: "goodsName",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "条码",
                  align: "center",
                  prop: "barcode",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "规格",
                  align: "center",
                  prop: "goodsSpec",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "单位",
                  align: "center",
                  prop: "unitName",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "单价",
                  align: "center",
                  prop: "unitPrice",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "金额",
                  align: "center",
                  prop: "unitMoney",
                  width: "155",
                },
              })
            : _vm._e(),
          ["140306"].includes(_vm.orderTypes)
            ? _c("el-table-column", {
                attrs: {
                  label: "备注",
                  align: "center",
                  prop: "remark",
                  width: "155",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }