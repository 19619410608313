<template>
  <div class="order-detail" v-loading="loading">
    <el-card class="card">
      <!-- 单据日期 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <div
          class="bill-nav"
          :class="{
            'nav-audit-icon': auditOrder,
            'nav-stop-icon': stopOrder,
            'nav-noAudit-icon': noAuditOrder,
            'nav-delete-icon': deleteOrder
          }"
        >
          <div class="x-w">
            <el-form-item :label="labelBillNo" prop="billNo">
              <el-input
                :disabled="true"
                v-model="form.billNo"
                :placeholder="labelBillNo"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker
                style="width: 100%"
                v-model="form.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="disabled"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <div v-if="isInStore">
              <el-form-item :label="labelInStore" prop="inStoreId">
                <el-select
                  v-model="form.inStoreId"
                  :placeholder="labelInStore"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('inStoreId')"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in storeData"
                    :key="item.storeId"
                    :label="item.storeName"
                    :value="item.storeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="isOutStore">
              <el-form-item :label="labelOutStore" prop="outStoreId">
                <el-select
                  v-model="form.outStoreId"
                  :placeholder="labelOutStore"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('outStoreId')"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in storeData"
                    :key="item.storeId"
                    :label="item.storeName"
                    :value="item.storeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div>
            <el-form-item label="单据备注" prop="remark">
              <el-input
                style="width: 200px"
                :disabled="disabled"
                v-model="form.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </el-form-item>
          </div>
        </div>
        <div class="bill-btn">
          <div class="x-f marB15">
            <el-button
              class="marR15"
              type="primary"
              plain
              icon="el-icon-download"
              size="mini"
              :disabled="disabled"
              @click="submitForm(true)"
              >保存
            </el-button>
            <el-button
              class="marR15"
              type="primary"
              icon="el-icon-plus"
              plain
              size="mini"
              :disabled="disabled"
              @click="AddBill"
              >保存并新增
            </el-button>
            <div v-if="disabledBills">
              <el-button
                class="marR15"
                type="primary"
                plain
                icon="el-icon-edit"
                size="mini"
                :disabled="disabledIntroduce"
                @click="selectBills"
                >引入源单
              </el-button>
            </div>
            <div :span="1.5" v-if="disabledBills">
              <el-button
                class="marR15"
                type="warning"
                icon="el-icon-refresh-right"
                size="mini"
                :disabled="disabledIntroduce"
                @click="cancelBills"
                >取消引入
              </el-button>
            </div>
            <div :span="1.5" v-if="isClose">
              <el-dropdown
                class="marR15"
                split-button
                type="primary"
                @click="auditBill('审核')"
                size="mini"
                plain
                icon="el-icon-circle-check"
                :disabled="showAudit"
                @command="auditBill"
              >
                审核
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="反审核">反审核</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div :span="1.5" v-else>
              <el-button
                class="marR15"
                split-button
                type="primary"
                @click="auditBill('审核')"
                size="mini"
                icon="el-icon-circle-check"
                :disabled="showAudit"
              >
                审核
              </el-button>
            </div>
            <div :span="1.5" v-if="isClose">
              <el-button
                plain
                icon="el-icon-switch-button"
                size="mini"
                :disabled="single"
                @click="auditBill('终止')"
                >终止
              </el-button>
            </div>
          </div>
          <div class="right-btn">
            <el-dropdown
              split-button
              type="primary"
              size="mini"
              plain
              v-print="PrintObj"
            >
              打印
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="打印预览">打印预览</el-dropdown-item>
                <el-dropdown-item command="打印设置">打印设置</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="bill-table">
          <el-table
            id="print"
            class="table"
            :class="{ 'table-icon': disabled }"
            ref="dragTable"
            :data="form.details"
            height="500"
            max-height="500"
            border
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column
              label="序号"
              type="index"
              width="80"
              class-name="allowDrag"
              align="center"
            />
            <el-table-column
              align="center"
              label="操作"
              width="120"
              v-if="!disabled"
            >
              <template v-slot="scope">
                <i
                  @click="row('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"
                ></i>
                <i
                  @click="row('del', scope.$index)"
                  class="el-icon-remove operateDel"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              label="商品编码"
              width="160"
              align="center"
              prop="goodsNo"
            >
              <template slot-scope="scope">
                <!-- <el-form-item
                  :prop="`details[${scope.$index}].goodsNo`"
                  :rules="rules[`details.goodsNo`]"
                > -->
                <el-input
                  v-model="scope.row.goodsNo"
                  :disabled="disabled"
                  @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                  @blur="watchScroll"
                >
                  <i
                    slot="suffix"
                    class="el-icon-more more"
                    @click="selectGoods"
                  ></i>
                </el-input>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              label="商品条码"
              width="160"
              align="center"
              prop="barcode"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="商品名称"
              align="center"
              prop="goodsName"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              label="规格"
              align="center"
              prop="goodsSpec"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              label="单位"
              align="center"
              prop="unitName"
              width="120"
            >
              <template slot-scope="scope">
                <el-select
                  @change="unitSalePriceChange($event, scope.row)"
                  v-model="scope.row.unitId"
                  :disabled="disabled"
                  @blur="
                    $refs.form.validateField(`details[${scope.$index}].unitId`)
                  "
                >
                  <el-option
                    v-for="item in scope.row.units"
                    :key="item.unitId"
                    :label="item.unitName"
                    :value="item.unitId"
                  />
                </el-select>
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
            <el-table-column
              v-if="isIn"
              :label="showNumber"
              align="center"
              show-overflow-tooltip
              width="120"
              prop="importUnitQty"
            >
            </el-table-column>
            <el-table-column :label="labelUnitQty" align="center" width="120">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'details.' + scope.$index + '.unitQty'"
                  :rules="rules[`details.unitQty`]"
                >
                  <el-input
                    @keyup.native="onkeyupUnitQty(scope.row)"
                    v-model.number="scope.row.unitQty"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="单价(元)"
              align="center"
              show-overflow-tooltip
              width="120"
              prop="unitPrice"
            >
            </el-table-column>
            <el-table-column
              :label="labelUnitMoney"
              align="center"
              width="120"
              prop="unitMoney"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.unitMoney }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="remark">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.remark"
                  :disabled="disabled"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <!-- 选择商品信息框 -->
      <SelectGoods
        ref="selectGoods"
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        @getGoodS="getGoodS"
      ></SelectGoods>
      <shopBills
        ref="shopBills"
        :openBills="openBills"
        :orderTypes="orderTypes"
        :moveAffirmObj="moveAffirmObj"
        @updateOpenBills="updateOpenBills"
        @shopBillsDate="shopBillsDate"
        @IntroductionStatus="IntroductionStatus"
      ></shopBills>
    </el-card>
  </div>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp'
import { listGoods } from '@/api/goods/goods' //商品列表
import {
  addOrder,
  updateOrderStatus,
  getOrderDetail,
  updateOrder
} from '@/api/shop/bill/moveIn' //门店
import { getOrderDetail as shopOrderDetail } from '@/api/shop/bill/shopBills'
import { getNewDate } from '@/utils/newDate' //单据时间格式
import { allStoreList } from '@/api/system/store' //仓库接口
import { getBillNo } from '@/api/codeRule' //单据号
import shopBills from '@/views/components/shopBills'
import { cancelImport } from '@/api/shop/bill/shopBills' //取消引入
import { unitSalePriceChange } from '@/utils/unitChange' //多级单位切换函数
export default {
  name: 'MoveInDetail',
  components: { SelectGoods, shopBills },

  dicts: ['bill_status'],
  props: {
    billNo: {
      required: true,
      type: String
    }
  },
  data () {
    //自定义校验规则
    let setUnitQty = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error('商品数量不能是零或空'))
        this.$message.error('商品数量不能是零或空')
      } else {
        if (value == 0) {
          callback(new Error('商品数量不能是零或空'))
          this.$message.error('商品数量不能是零或空')
        }
        callback()
      }
    }
    return {
      moveAffirmObj: {}, //引入信息
      Introduction: false, //引入状态
      isClose: true, //关闭按钮
      isInStore: false, //是否显示调入仓库
      isOutStore: false, //是否显示调出仓库
      labelInStore: '调入仓库', //调入仓库标题
      labelOutStore: '调出仓库', //调出仓库标题
      orderTypes: '', //引入源单   单据类型
      disabledIntroduce: true, //引入单据禁用状态
      labelUnitMoney: '金额(元)', //表格金额
      showNumber: '发货数量', //表格显示给用户看的
      labelUnitQty: '数量', //计算标题
      labelBillNo: '单据编号', //编号命名
      disabledBills: false, //引入单据默认不显示
      isIn: false, //表格收货数量
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      billType: '', //单据类型
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      openBills: undefined, //引入源单开关
      showAudit: true, //审核
      //打印
      PrintObj: {
        id: 'print'
      },
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //当前表格宽度
      scrollLeft: null,
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //终止
      single: true,
      //仓库数据
      storeData: [],
      //员工数据
      employeeData: [],
      //表单数据
      form: {
        billDate: '', //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: '', //单据审核状态
        //单据表格-订单明细
        details: [{}]
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入单据编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          { required: true, message: '请输入制单日期', trigger: 'blur' }
        ],
        inStoreId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        outStoreId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        storeId: [{ required: true, message: '请选择仓库', trigger: 'change' }],
        'details.unitQty': [{ validator: setUnitQty, trigger: 'blur' }]
      }
    }
  },
  watch: {
    //侦听传过来的单据类型
    billNo: {
      handler (newVal) {
        this.changeBillType(newVal)
        this.billType = newVal
        if (this.billType === '140305') {
          this.isInStore = true //是否显示调入仓库
          this.isOutStore = true //是否显示调出仓库
          this.labelInStore = '调入仓库' //调入仓库标题
          this.labelOutStore = '调出仓库' //调出仓库标题
          //引入源单
          this.disabledBills = true
          //收货数量
          this.isIn = true
          //显示数量
          this.showNumber = '发货数量'
          //计算数量
          this.labelUnitQty = '收货数量'
        } else if (this.billType === '140306') {
          this.isInStore = true //是否显示调入仓库
          this.isOutStore = true //是否显示调出仓库
          this.labelInStore = '调入仓库' //调入仓库标题
          this.labelOutStore = '调出仓库' //调出仓库标题
          //引入源单
          this.disabledBills = true
          //收货数量
          this.isIn = true
          //显示数量
          this.showNumber = '订货数量'
          //计算数量
          this.labelUnitQty = '发货数量'
        } else if (this.billType === '140307') {
          //生产   仓库调入
          this.isInStore = true //是否显示调入仓库
          this.labelInStore = '仓库' //调入仓库标题
        } else if (this.billType === '140308') {
          //领料   仓库调出
          this.isOutStore = true //是否显示调出仓库
          this.labelOutStore = '仓库' //调出仓库标题
        } else if (this.billType === '140310') {
          //门店盘盈
          this.isClose = false //是否显示关闭按钮
          this.isInStore = true //是否显示调入仓库
          this.labelInStore = '盘盈仓库' //调入仓库标题
          this.labelUnitQty = '盘盈数量' //数量标题
          this.labelUnitMoney = '盘盈金额' //金额标题
        } else if (this.billType === '140311') {
          //门店盘亏
          this.isClose = false //是否显示关闭按钮
          this.isOutStore = true //是否显示调出仓库
          this.labelOutStore = '盘亏仓库' //调出仓库标题
          this.labelUnitQty = '盘亏数量' //数量标题
          this.labelUnitMoney = '盘亏金额' //金额标题
        }
      },
      immediate: true
    },
    //侦听路由
    $route: {
      async handler () {
        this.reset()
        if (this.$route.query.type === 'Update') {
          if (this.$route.query.billId) {
            this.loading = true
            //获取仓库详情
            const res = await allStoreList()
            this.storeData = res.data
            //获取单据详情
            const res1 = await getOrderDetail(
              this.$route.query.billId,
              this.urlDetail
            )
            //单据赋值
            this.form = res1.data
            if (this.form.details.length === 0) {
              this.form.details = [{}]
            }
            //是否引入
            if (this.form.sourceBillId) {
              this.Introduction = true
            }
            this.loading = false
          }
        } else if (this.$route.query.type == 'updateMoveAffirm') {
          this.getBasicData()
          this.form.inStoreId = this.$route.query.moveAffirm.inStoreId
          this.form.outStoreId = this.$route.query.moveAffirm.outStoreId
          this.openBills = true
          this.orderTypes = '140305'
          this.moveAffirmObj = {
            billTypes: [140306, 111],
            inStoreId: this.$route.query.moveAffirm.inStoreId,
            billId: this.$route.query.moveAffirm.billId
          }
        } else {
          this.getBasicData()
          this.disabled = false
          this.auditOrder = false
        }
        //深度监听,同时也可监听到param参数变化
      },
      deep: true,
      immediate: true
    },
    //侦听是否开启关闭按钮
    'form.billStatus': {
      handler (newVal) {
        if (this.form.billStatus == '2') {
          //打开审核图标
          this.auditOrder = true
          //打开关闭按钮
          this.single = false
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
          if (this.billType === '140305' || this.billType === '140306') {
            //引入源单按钮
            this.disabledBills = false
          }
        } else if (this.form.billStatus == '3') {
          //打开已删除图标
          this.deleteOrder = true
          //禁用关闭按钮
          this.single = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
          if (this.billType === '140305' || this.billType === '140306') {
            //引入源单按钮
            this.disabledBills = false
          }
        } else if (this.form.billStatus == '0') {
          //禁用关闭按钮
          this.single = true
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          if (this.billType === '140305' || this.billType === '140306') {
            //引入源单按钮
            this.disabledBills = true
          }
        } else if (this.form.billStatus == '4') {
          //打开关闭图标
          this.stopOrder = true
          //禁用关闭按钮
          this.single = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
          if (this.billType === '140305' || this.billType === '140306') {
            //引入源单按钮
            this.disabledBills = false
          }
        } else if (this.form.billStatus == '') {
          //禁用审核按钮
          this.showAudit = true
          //禁用关闭按钮
          this.single = true
        }
      },
      immediate: true
    },
    //侦听调入是否开启引入源单按钮
    'form.inStoreId': {
      handler (newVal) {
        if (this.billType === '140305') {
          if (this.form.inStoreId) {
            this.disabledIntroduce = false
          } else {
            this.disabledIntroduce = true
          }
        }
      },
      immediate: true
    },
    //侦听调出是否开启引入源单按钮
    'form.outStoreId': {
      handler (newVal) {
        if (this.billType === '140306') {
          if (this.form.outStoreId) {
            this.disabledIntroduce = false
          } else {
            this.disabledIntroduce = true
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    //取消当前业务订单
    cancelBills () {
      this.$confirm('此操作将取消上一次引入并清空表格商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (this.form.billId) {
            await cancelImport(this.form.billId)
          }
          this.Introduction = false
          this.form.details = [{}]
        })
        .catch(() => {})
    },
    //引入源单商品
    async shopBillsDate (val) {
      if (!val) {
        let { rows } = await shopOrderDetail([this.form.billId])
        val = rows
      }
      val.forEach(v => {
        v.importUnitQty = v.unitQty
        v.unitMoney = v.unitQty * v.unitPrice
        v.sourceBillId = v.billId
        v.sourceBillIdNo = v.billNo
        v.sourceBillDetailId = v.billDetailId
        v.billId = null
        v.billDetailId = null
      })
      this.form.details = val
    },
    //单据引入状态
    IntroductionStatus (val) {
      this.Introduction = val
    },
    //引入源单
    selectBills () {
      if (this.Introduction) {
        this.$message.error('如需重新选择源单，请先取消引入')
        return
      }
      if (this.billType === '140305') {
        this.openBills = true
        this.orderTypes = '140305'
        this.$refs.shopBills.queryParams.billTypes = [140306, 111]
        this.$refs.shopBills.queryParams.inStoreId = this.form.inStoreId
      } else if (this.billType === '140306') {
        this.openBills = true
        this.orderTypes = '140306'
        this.$refs.shopBills.queryParams.outStoreId = this.form.outStoreId
      }
    },
    //引入单据函数
    selectOrder () {
      this.form.details = []
      if (this.billType === '140305') {
        this.openBills = true
        this.orderTypes = '140305'
        this.$refs.shopBills.queryParams.billTypes = [140306, 111]
        this.$refs.shopBills.queryParams.inStoreId = this.form.inStoreId
      } else if (this.billType === '140306') {
        this.openBills = true
        this.orderTypes = '140306'
        this.$refs.shopBills.queryParams.outStoreId = this.form.outStoreId
      }
    },
    //关闭弹窗
    updateOpenBills (val) {
      this.openBills = val
    },
    changeBillType (billNo) {
      if (billNo === '140305') {
        this.urlDetail = '/api/system/shop/bill/in/getDetail'
        this.urlUpdate = '/api/system/shop/bill/in/update'
        this.urlAdd = '/api/system/shop/bill/in/save'
        this.urlBillStatus = '/api/system/shop/bill/in/updateBillStatus'
      } else if (billNo === '140306') {
        this.urlDetail = '/api/system/shop/bill/out/getDetail'
        this.urlUpdate = '/api/system/shop/bill/out/update'
        this.urlAdd = '/api/system/shop/bill/out/save'
        this.urlBillStatus = '/api/system/shop/bill/out/updateBillStatus'
      } else if (billNo === '140307') {
        this.urlDetail = '/api/system/shop/bill/produce/getDetail'
        this.urlUpdate = '/api/system/shop/bill/produce/update'
        this.urlAdd = '/api/system/shop/bill/produce/save'
        this.urlBillStatus = '/api/system/shop/bill/produce/updateBillStatus'
      } else if (billNo === '140308') {
        this.urlDetail = '/api/system/shop/bill/picking/getDetail'
        this.urlUpdate = '/api/system/shop/bill/picking/update'
        this.urlAdd = '/api/system/shop/bill/picking/save'
        this.urlBillStatus = '/api/system/shop/bill/picking/updateBillStatus'
      } else if (billNo === '140310') {
        this.labelBillNo = '盘盈单号'
        this.urlDetail = '/api/system/shop/bill/inventory/surplus/getDetail'
        this.urlUpdate = '/api/system/shop/bill/inventory/surplus/update'
        this.urlAdd = '/api/system/shop/bill/inventory/surplus/save'
        this.urlBillStatus =
          '/api/system/shop/bill/inventory/surplus/updateBillStatus'
      } else if (billNo === '140311') {
        this.labelBillNo = '盘亏单号'
        this.urlDetail = '/api/system/shop/bill/inventory/loss/getDetail'
        this.urlUpdate = '/api/system/shop/bill/inventory/loss/update'
        this.urlAdd = '/api/system/shop/bill/inventory/loss/save'
        this.urlBillStatus =
          '/api/system/shop/bill/inventory/loss/updateBillStatus'
      }
    },
    //数量键盘抬起
    onkeyupUnitQty (row) {
      const unitMoney = row.unitQty * row.unitPrice
      this.$set(row, 'unitMoney', unitMoney)
    },
    unitSalePriceChange,
    //表单重置
    reset () {
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭关闭图标
      this.stopOrder = false

      //表单数据
      this.form = {
        billDate: '', //单据日期
        billId: undefined, //单据id
        //单据状态
        billStatus: '',
        // //单据编号
        billNo: undefined,
        //单据表格-订单明细
        details: [{}]
      }
      this.resetForm('form')
    },
    //监听数据变化失去焦点时  滚动条的位置
    watchScroll () {
      if (this.scrollLeft) this.savescroll()
    },
    // 获取滚动的宽度
    scrollEvent (e) {
      this.scrollLeft = e.target.scrollLeft
    },
    // 数据刷新后设置滚动条当前滚动的位置
    savescroll () {
      this.$nextTick(() => {
        setTimeout(() => {
          let scroll = this.$el.querySelector('.el-table__body-wrapper')
          scroll.scrollLeft = this.scrollLeft
        }, 10)
      })
    },
    //初始化页面
    async getBasicData () {
      this.loading = true
      //获取单据日期
      this.form.billDate = getNewDate()
      //获取仓库详情
      const res = await allStoreList()
      this.storeData = res.data
      //新增时获取单据编号
      const res2 = await getBillNo(this.billType)
      this.form.billNo = res2
      this.loading = false
    },
    //保存并新增单据
    async AddBill () {
      try {
        await this.$confirm('是否保存并新增单据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        await this.submitForm(false)

        this.loading = true

        const res1 = await getBillNo(this.billType)
        this.form.billNo = res1
        this.loading = false
      } catch {}
    },
    //订单明细表格合计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let name = ''
        if (this.billType === '140305' || this.billType === '140306') {
          if (index === 10) {
            name = 'unitMoney'
          } else if (index === 8) {
            name = 'unitQty'
          } else {
            sums[index] = ''
            return
          }
        } else {
          if (index === 9) {
            name = 'unitMoney'
          } else if (index === 7) {
            name = 'unitQty'
          } else {
            sums[index] = ''
            return
          }
        }

        const values = data.filter(item => !(item[`${name}`] === undefined))
        const values2 = values.map(i => Number(i[`${name}`]))
        sums[index] = values2.reduce((prev, curr) => {
          return prev + curr
        }, 0)
      })
      return sums
    },

    //选中的商品
    getGoodS (value) {
      this.form.details = this.form.details.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )

      value.forEach(v => {
        this.$set(v, 'unitQty', 0)
        this.$set(v, 'unitPrice', 0)
        this.$set(v, 'unitMoney', 0)
        v.unitPrice = v.salePrice
        v.remark = ''
        v.unitMoney = v.unitQty * v.unitPrice
      })
      this.form.details.push(...value)
    },

    //表格单元格回车事件
    keyup (name, event, index) {
      //判断是否输入编码或条码
      if (this.form.details[`${index}`][`${name}`]) {
        let goodsTypes = []
        if (this.billType === '140308') {
          goodsTypes = [2, 3]
        } else {
          goodsTypes = undefined
        }
        listGoods({
          [name]: this.form.details[`${index}`][`${name}`],
          goodsTypes
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //原始赋值
            response.rows[0].remark = ''
            response.rows[0].unitQty = 0
            response.rows[0].unitPrice = response.rows[0].salePrice
            response.rows[0].unitMoney = 0

            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.form.details, index, response.rows[0])
          } else {
            this.queryCode = this.form.details[`${index}`][`${name}`]
            if (this.billType === '140308') {
              this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
            } else {
              this.$refs.selectGoods.queryParams.goodsTypes = undefined
            }

            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        if (this.billType === '140308') {
          this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
        } else {
          this.$refs.selectGoods.queryParams.goodsTypes = undefined
        }
        this.OpenGoods = true
      }
    },

    //选择商品方法
    selectGoods () {
      if (this.billType === '140308') {
        this.$refs.selectGoods.queryParams.goodsTypes = [2, 3]
      } else {
        this.$refs.selectGoods.queryParams.goodsTypes = undefined
      }
      this.OpenGoods = true
    },
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.form.details.splice(index + 1, 0, {})
      } else {
        if (this.form.details.length <= 1) return
        this.form.details.splice(index, 1)
      }
    },
    //审核单据方法
    async auditBill (name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.form.billStatus != '0') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 2,
            billType: this.billType
          }
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus)
          this.form = res.data
          // 弹出提示
          this.$message.success('审核成功')
          //禁用全部输入
          this.disabled = true
          //打开已审核图标
          this.auditOrder = true
        } else if (name === '反审核') {
          if (this.form.billStatus != '2') {
            this.$message.error('此单据不是已审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 0,
            billType: this.billType
          }
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus)
          this.form = res.data
          // 弹出提示
          this.$message.success('反审核成功')
          //恢复全部输入
          this.disabled = false
          //关闭已审核图标
          this.auditOrder = false
          //打开未审核图标
          this.noAuditOrder = true
        } else if (name === '终止') {
          if (this.form.billStatus != '2') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4,
            billType: this.billType
          }
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus)
          this.form = res.data
          // 弹出提示
          this.$message.success('单据关闭成功')
          //关闭已审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false

          //打开关闭图标
          this.stopOrder = true
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm (isBool) {
      if (['140305', '140306'].includes(this.billType)) {
        if (this.form.outStoreId === this.form.inStoreId ?? false) {
          this.$message.error('调入仓库和调出仓库不能选择同一个仓库')
          return
        }
      }

      if (
        !(JSON.stringify(this.form.details[0]) === '{}') &&
        !(this.form.details[0].goodsId === undefined)
      ) {
        this.$refs['form'].validate(async valid => {
          if (valid) {
            if (this.form.billId) {
              const res = await updateOrder(this.form, this.urlUpdate)
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('修改单据成功')
              } else {
                await this.reset()
                this.$modal.msgSuccess('保存并新增单据成功')
              }
            } else {
              const res = await addOrder(this.form, this.urlAdd)
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('新增单据成功')
              } else {
                await this.reset()
                this.$modal.msgSuccess('保存并修改单据成功')
              }
            }
          }
        })
      } else {
        this.$message.error('单据明细不能为空')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail {
  padding: 10px;
  background-color: #f3f3f3;

  .el-card {
    min-height: 87vh;
    padding: 10px;

    .bill-nav {
      ::v-deep.el-input__inner,
      ::v-deep.el-textarea__inner {
        width: 200px;
      }
    }

    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已审核图标
    .nav-audit-icon {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已审核图标
    .nav-delete-icon {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已关闭图标
    .nav-stop-icon {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //按钮区域
    .bill-btn {
      display: flex;
      justify-content: space-between;

      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;

        &.show {
          display: block;
        }
      }
    }

    .bill-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }

      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}

//表格
.table {
  width: 100%;
}
</style>
